@import "variables.scss";
.header {
    position: fixed;
    width: 100%;
    z-index: 20;
    background-image: url('../components/images/header/header@4x.webp');
    aspect-ratio: 1200/84;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    height: $simple_header_height_widescreen;
    @media screen and (max-width: calc($widescreen-breakpoint)) {
        height: $simple_header_height_desktop;
    }
    @media screen and (max-width: calc($desktop-breakpoint)) {
        background-image: url('../components/images/header/header-mobile@2x.webp');
        aspect-ratio: 320/41;
        height: $simple_header_height_tablet;
    }
    @media screen and (max-width: calc($mobile-breakpoint + 100px)) {
        background-image: url('../components/images/header/header-mobile@2x.webp');
        aspect-ratio: 320/41;
        height: $simple_header_height_mobile;
    }
}
// anchor element that expands to fit container
.headerLink {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
