@import "variables.scss";
.pageWrapper {
    padding: 40px 90px calc($footer_height * 2);
    @media screen and (max-width: calc($tablet_breakpoint + 60px)) {
        padding-left: 60px;
        padding-right: 60px;
        padding-top: 20px;
    }
    @media screen and (max-width: $mobile_breakpoint) {
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 24px;
    }
}

main.pageWithHomeHeader {
    padding-top: calc($simple_header_height_widescreen + 2rem);
    @media screen and (max-width: calc($widescreen-breakpoint)) {
        padding-top: calc($simple_header_height_desktop + 2rem);
    }
    @media screen and (max-width: calc($desktop-breakpoint)) {
        padding-top: calc($simple_header_height_tablet + 2rem);
    }
    @media screen and (max-width: calc($mobile-breakpoint + 100px)) {
        padding-top: calc($simple_header_height_mobile + 2rem);
    }
}
