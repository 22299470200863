@import "variables.scss";
.storyPageContainer {
    @media screen and (min-width: $desktop-breakpoint) and (min-height: $toc_height) {
        background: linear-gradient(
            to right,
            rgba(245, 233, 209, 0.36) 75%,
            rgba(137, 104, 69, 0.44) 100%
        ); // Add the gradient if the TOC is there. This doesn't look quite right compared to Adobe XD but is very close
    }
}

.storyPage {
    padding-top: 20px;
    // Fixed header height
    margin-top: calc($header_height / 2);
    @media screen and (min-width: $desktop-breakpoint) and (min-height: $toc_height) {
        margin-right: $toc_width; // only save space if the toc is visible
    }
    max-width: $page_max_width;

    article + article {
        padding-top: 1rem;
        @media screen and (min-width: $tablet_breakpoint) {
            padding-top: 2rem;
        }

        @media screen and (min-width: $desktop_breakpoint) {
            padding-top: 3rem;
        }
    }

    h2 {
        // Needed to offset the fixed header size for scrolling
        scroll-margin-top: calc($header_height);
    }
}
.storyPageWithHomeHeader {
    padding-top: 0rem;
    margin-top: 0rem;

    h2 {
        scroll-margin-top: calc($simple_header_height_widescreen + 1rem);
        @media screen and (max-width: calc($widescreen-breakpoint)) {
            scroll-margin-top: calc($simple_header_height_desktop + 1rem);
        }
        @media screen and (max-width: calc($desktop-breakpoint)) {
            scroll-margin-top: calc($simple_header_height_tablet + 1rem);
        }
        @media screen and (max-width: calc($mobile-breakpoint + 100px)) {
            scroll-margin-top: calc($simple_header_height_mobile + 1rem);
        }
    }
}
