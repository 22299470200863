@import "variables.scss";
.arrowHead {
    position: absolute;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    top: 70vh;
    color: white;
    width: 100%;
    cursor: pointer;
    z-index: 10;
    @media screen and (max-width: calc($mobile-breakpoint + 20px)) {
        font-size: 12px;
        top: 80vh;
    }

    // pulsing animation
    // animation: pulse 2s infinite;
    // @keyframes pulse {
    //     0% {
    //         transform: scale(1) translate(-50%, -50%);
    //     }
    //     50% {
    //         transform: scale(1.5) translate(-36.6%, -36.6%);
    //     }
    //     100% {
    //         transform: scale(1) translate(-50%, -50%);
    //     }
    // }
}
