$mobile-breakpoint: 480px;
$tablet-breakpoint: 767px;
$desktop-breakpoint: 1024px;
$widescreen-breakpoint: 1600px;

$full_chrome_height: 800px;

$toc_width: 257px;
$toc_height: 600px;

$font_thin: 100;
$font_extra_light: 200;
$font_light: 300;
$font_regular: 400;
$font_medium: 450;
$font_semibold: 500;
$font_bold: 700;
$font_extrabold: 700;
$font_condensed_black: 700;
$font_black: 900;

$color_yellow: #f3bb41;
$color_purple: #691256;
$color_light_green: #bdcd86;

$header_height: 84px;
$footer_height: 60px;
$page_max_width: 1200px;

$simple_header_height_widescreen: 120px;
$simple_header_height_desktop: 84px;
$simple_header_height_tablet: 80px;
$simple_header_height_mobile: 60px;

$three_cards_visible_width: calc($tablet-breakpoint - 1px);

@mixin origin-stories {
    background-color: $color_light_green;
    color: #33541d;
}

@mixin ice-age-texas {
    background-color: #8dd2ef;
    color: #172b66;
}

@mixin daily-life {
    background-color: #172b66;
    color: #8dd2ef;
}

$navigation-under-video-breakpoint: calc($desktop-breakpoint);
